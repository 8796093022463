// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@grnhse/wrailspack/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@grnhse/wrailspack/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-size: 12px;
}
body.forms-statement {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./app/webpack/javascripts/tinymce/tinymce-extras/email.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":["body {\n  font-size: 12px;\n\n  &.forms-statement {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
