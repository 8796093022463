// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@grnhse/wrailspack/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@grnhse/wrailspack/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  font-family: Helvetica, Arial, sans-serif;
  height: 100%;
}

body {
  font-size: 14px;
  margin: 8px;
}

a {
  text-decoration: none;
  color: #3574d6;
}
a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./app/webpack/javascripts/tinymce/tinymce-extras/default.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;;AAEA;EACE,qBAAA;EACA,cAAA;AACF;AACE;EACE,0BAAA;AACJ","sourcesContent":["html, body {\n  font-family: Helvetica, Arial, sans-serif;\n  height: 100%;\n}\n\nbody {\n  font-size: 14px;\n  margin: 8px;\n}\n\na {\n  text-decoration: none;\n  color: #3574d6;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
