// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@grnhse/wrailspack/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@grnhse/wrailspack/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body p {
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #15372c;
  margin: 0;
  padding: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
}
body a {
  color: #078361;
}
body ul, body ol {
  color: #15372c;
}
body ::selection {
  background-color: #ecf3ff;
}`, "",{"version":3,"sources":["webpack://./app/webpack/javascripts/tinymce/tinymce-extras/ai_email.scss","webpack://./app/assets/stylesheets/redesign_2022/birch_colors.scss"],"names":[],"mappings":"AAGE;EACE,sCAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,cCIqB;EDHrB,SAAA;EACA,UAAA;EACA,qBAAA;EACA,uBAAA;AAFJ;AAIE;EACE,cCRe;ADMnB;AAKE;EACE,cCPqB;ADIzB;AAKE;EACE,yBAAA;AAHJ","sourcesContent":["@import '../../../../assets/stylesheets/redesign_2022/birch_colors';\n\nbody {\n  p {\n    font-family: Untitled Sans, sans-serif;\n    font-weight: 400;\n    font-size: 1rem;\n    line-height: 1.5rem;\n    color: $birch_medium_evergreen;\n    margin: 0;\n    padding: 0;\n    padding-inline-end: 0;\n    padding-inline-start: 0;\n  }\n  a {\n    color: $birch_link_green;\n  }\n  \n  ul, ol {\n    color: $birch_medium_evergreen;\n  }\n  ::selection {\n    background-color: $birch_extra_light_iris;\n  }\n}","$birch_white: #ffffff;\n$birch_lightest_grey: #f9faf9;\n$birch_light_grey: #eff1f0;\n$birch_medium_grey: #e0e5e3;\n$birch_dark_grey: #ccd4d1;\n$birch_darker_grey: #677d76;\n$birch_darkest_grey: #526b63;\n$birch_link_green: #078361;\n$birch_dark_green: #047957;\n$birch_medium_green: #24a47f;\n$birch_light_green: #4cb398;\n$birch_extra_light_green: #f3fffb;\n$birch_medium_evergreen: #15372c;\n$birch_light_evergreen: #335247;\n$birch_dark_poppy: #b52617;\n$birch_medium_poppy: #d8372a;\n$birch_light_poppy: #e3564b;\n$birch_extra_light_poppy: #fff7f8;\n$birch_dark_iris: #054ab2;\n$birch_medium_iris: #3574d6;\n$birch_light_iris: #578bdd;\n$birch_lighter_iris: #d6e6ff;\n$birch_extra_light_iris: #ecf3ff;\n$birch_dark_yellow: #f2cd00;\n$birch_medium_yellow: #ffe607;\n$birch_light_yellow: #fff9c1;\n$birch_extra_light_yellow: #fffbd7;\n$birch_green_e100: #c9f0e6;\n$birch_marigold_e200: #ffdaa9;\n$birch_light_ground: #f5f5f5;\n\n// This color is not found in Birch but is the redesign background color\n$birch_background_color: #F7F8F7;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
